import React from 'react';
import PropTypes from 'prop-types';
import { StyledNextLink } from './NextLink.styled';

const NextLink = ({ children, to }) => {
  const handleClick = (event) => {
    const target = event.currentTarget;
    target.classList.add('clicked');
  }

  return (
    <StyledNextLink to={to} onClick={handleClick}>
      {children}
      <span className="arrow"></span>
      <span className="circle"></span>
    </StyledNextLink>
  )
};

NextLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
}

export default NextLink;
