import styled from '@emotion/styled';
import { Link } from 'gatsby';

export const StyledNextLink = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 0 70px 0 0;
  color: inherit;
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;

    .arrow {
      right: 7px;
    }

    .circle {
      opacity: .5;
    }
  }

  &.clicked {
    .arrow {
      right: -7px;
      opacity: 0;
    }

    .circle {
      opacity: 0;
      transition: opacity .3s .15s;
    }
  }

  .arrow {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transition: opacity .3s, right .3s;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
    }

    &::before {
      width: 50px;
      height: 2px;
      background-color: #222222;
      transform: translateY(-50%);
    }

    &:after {
      width: 10px;
      height: 10px;
      border: 2px solid #222222;
      border-left: none;
      border-bottom: none;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .circle {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #a92d37;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: opacity .3s;
  }
`;
