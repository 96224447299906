import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import NextLink from '../components/NextLink';

import style from '../assets/style/pages/home.module.scss';

export default ({ data }) => {

  const backgroundImage = data.homeBg.childImageSharp.fluid;

  return (
    <Layout isHome={true}>
      <SEO title="Strona główna" />
      <BackgroundImage
        Tag="section"
        className={style.homeSection}
        fluid={backgroundImage}
        style={{
          backgroundSize: '1630px',
          backgroundPosition: 'bottom right'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 offset-lg-1 col-lg-11">
              <h1>BPD Rafał Matusik</h1>
              <h2>Biuro projektów drogowych</h2>
              <NextLink to="/o-nas/">Więcej</NextLink>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Layout>
  )
};

export const query = graphql`
  query {
    homeBg: file(relativePath: { eq: "assets/images/home-bg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
